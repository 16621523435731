export default [
  {
    title: 'breadcrumb.report',
    header: 'breadcrumb.report',
    icon: '',
    children: [
      {
        title: 'breadcrumb.balanceReportVip',
        route: 'balance-report-vip',
        icon: '',
        resource: 'balance-report-vip',
        action: 'read',
      },
      {
        title: 'breadcrumb.cockBalanceReport',
        route: 'cock-balance-report',
        icon: '',
        resource: 'balance-report',
        action: 'read',
      },
      {
        title: 'breadcrumb.baccaratBalanceReport',
        route: 'baccarat-balance-report',
        icon: '',
        resource: 'balance-report',
        action: 'read',
      },
      {
        title: 'breadcrumb.slotBalanceReport',
        route: 'slot-balance-report',
        icon: '',
        resource: 'balance-report',
        action: 'read',
      },
      {
        title: 'breadcrumb.lottoBalanceReport',
        route: 'lotto-balance-report',
        icon: '',
        resource: 'balance-report-lotto',
        action: 'read',
      },
      {
        title: 'breadcrumb.lotto12BalanceReport',
        route: 'lotto12-balance-report',
        icon: '',
        resource: 'balance-report-lotto12',
        action: 'read',
      },
      {
        title: 'breadcrumb.lotteryBalanceReport',
        route: 'lottery-balance-report',
        icon: '',
        resource: 'balance-report-lottery',
        action: 'read',
      },
      {
        title: 'breadcrumb.parleyReport',
        route: 'parley-report',
        icon: '',
        resource: 'parley-report',
        action: 'read',
      },
    ],
  },
]
